

body {
	margin: 0;
	background-color: transparent;
	color: #fff;
	font-family: Monospace;
	font-size: 13px;
	line-height: 24px;
	overscroll-behavior: none;
	
}

a {
	color: #ff0;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

button {
	cursor: pointer;
	text-transform: uppercase;
}

#info {
	position: absolute;
	top: 0px;
	width: 100%;
	padding: 10px;
	box-sizing: border-box;
	text-align: center;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
	z-index: 1;
	
}

a,
button,
input,
select {
	pointer-events: auto;
}
body{
	user-select:none;
	-webkit-user-select:none;
	-ms-user-select: none;
	-moz-user-select:none;
	-khtml-user-select:none;
	-webkit-user-drag:none;
	-khtml-user-drag:none;
	}
.dg.ac {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 2 !important;
	/* TODO Solve this in HTML */
}

#overlay {
	position: absolute;
	font-size: 16px;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: transparent;
}

#overlay button {
	background: transparent;
	border: 0;
	border: 1px solid rgb(255, 255, 255);
	border-radius: 4px;
	color: #ffffff;
	padding: 12px 18px;
	text-transform: uppercase;
	cursor: pointer;
}


#notSupported {
	width: 50%;
	margin: auto;
	background-color: #f00;
	margin-top: 20px;
	padding: 10px;
}



#mobileInterface {
	position: fixed;
	width: calc(100% - 20px);
	height: 50vh;
	pointer-events: none;
	z-index: 11;
	top: auto;
	bottom: 120px;
	left: 10px;
	touch-action: manipulation;
  }
  #mobileInterface #joystickWrapper1 {
	pointer-events: auto;
	display: block;
	position: absolute;
	bottom: 0;
	left: 0;
	background-color: transparent;
	width: 120px;
	height: 120px;
	z-index: 12;
	touch-action: manipulation;
	background-color: transparent;
  }
  #mobileInterface #joystickWrapper2 {
	pointer-events: auto;
	display: block;
	position: absolute;
	bottom: 0;
	right: 0;
	left: auto;
	background-color: transparent;
	width: 50vw;
	height: 140px;
	z-index: 12;
	touch-action: manipulation;
	display: none;
  }
  
  #mobileInterface #joystickWrapper2 p {
	position: relative;
	display: inline-block;
	color: white;
	opacity: 1;
	margin: 0;
	padding: 0;
	letter-spacing: 4px;
	margin-left: 4px;
  }

  
/* ##############
loading screen 
#################*/
  #loading-screen {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #000000;
	opacity: 1;
 	transition: 3s opacity;
}

#loading-screen.fade-out {
    opacity: 0;
}

#loader {
    display: block;
    position: relative;
    left: 50%;
    top: 25%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #500cd8;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #349aed;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #160691;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}




  .noSelect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: transparent;
  }